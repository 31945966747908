import React, {useEffect, useState} from "react";
import QuizService from "../services/Quiz";
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Pagination from 'rc-pagination';
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {Link, useParams} from "react-router-dom";
import {convertToSlug, isNotEmptyObject, setBg} from "../utils/helpers";

const buttonItemRender = (current, type, element) => {
    if (type === 'prev') {
        return <div><LeftOutlined /></div>;
    }
    if (type === 'next') {
        return <div><RightOutlined /></div>;
    }

    return element;
}

export const QuizLoading = () => {
    const level = 8;
    let skeletons = [];
    for (let i = 0; i < level; i++) {
        skeletons.push(
            <div className="col-12 col-md-3 quiz-item mb-4" key={i}>
                <Skeleton height={160}/>
                <div className="quiz-item-info">
                    <Skeleton width={60} />
                    <div className="quiz-title my-2"><Skeleton count={2} /></div>
                    <div className="quiz-total-answer"><Skeleton width={120} /></div>
                </div>
            </div>
        );
    }

    return (
        <>
            {skeletons}
        </>
    )
}

export const PaginationLoading = () => {
    const level = 5;
    let skeletons = [];
    let margin = '0 3px';
    for (let i = 0; i < level; i++) {
        if (i === 0 || i === level.length - 1) {
            margin = '7px';
        }

        skeletons.push(
            <Skeleton key={i} circle={true} width={28} height={28} style={{margin: margin}} />
        );
    }

    return (
        <>
            <ul className="rc-pagination mt-5">
                {skeletons}
            </ul>
        </>
    )
}

const Quiz = ({search, locale}) => {

    const pageSize = 12;

    const [quizzes, setQuizzes] = useState([]);

    const [loading, setLoading] = useState(true);

    const [total, setTotal] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);

    const onPageChange = async (page) => {
        setCurrentPage(page);
        await getQuizzes(page);
    };

    const getQuizzes = async (page) => {
        setLoading(true);
        const quizzes = await QuizService.get({
            'filters[status][$eq]': 'approved',
            locale: locale,
            pagination: {
                page: page,
                pageSize: pageSize,
            },
            filters: {
                name: {
                    $contains: search
                }
            }
        });

        setTotal(quizzes?.data?.['pagination'].total);
        setQuizzes(quizzes?.data?.results);
        setLoading(false);
    }

    useEffect(() => {
        // setLoading(true);
        getQuizzes(currentPage);
    }, [search, locale]);

    return (
        <>
            <div style={{backgroundColor: '#f2f2f2'}}>
                <div className="container pt-5">
                    <div className="row quiz">
                        {
                            !_.isEmpty(quizzes) ?
                                _.map(quizzes, quiz => (
                                    <div className="col-12 col-md-3 quiz-item mb-4" key={quiz.id}>
                                        {
                                            loading ?
                                                <Skeleton height={160}/>
                                                :
                                                <div className="quiz-item-header text-uppercase" style={{backgroundColor: setBg()}}>{quiz?.name.charAt(0)}</div>
                                        }

                                        <div className="quiz-item-info">
                                            {
                                                loading ?
                                                    <Skeleton width={60} />
                                                    :
                                                    <div className="text-uppercase quiz-tag mt-2">Quiz</div>
                                            }

                                            <Link to={`/${locale}/quiz/${quiz.id}/${convertToSlug(quiz.name)}`} className="quiz-title my-2">{loading ? <Skeleton count={2} /> : quiz?.name}</Link>
                                            <div className="quiz-total-answer">{loading ? <Skeleton width={120} /> : quiz?.['questions'].count + ' Questions'}</div>
                                        </div>
                                    </div>
                                ))
                                :
                                (
                                    !isNotEmptyObject(quizzes) && isNotEmptyObject(search) ?
                                        <div className={'text-center pb-5'}>Không có dữ liệu</div>
                                        : <QuizLoading />
                                )
                        }
                        {
                            !_.isEmpty(quizzes) ?
                                (
                                    <Pagination
                                        style={{marginTop: '30px'}}
                                        onChange={onPageChange}
                                        current={currentPage}
                                        total={total}
                                        pageSize={pageSize}
                                        itemRender={buttonItemRender}
                                    />
                                )
                                :
                                // <PaginationLoading />
                                    (
                                        !isNotEmptyObject(quizzes) && isNotEmptyObject(search) ?
                                            null
                                            : <PaginationLoading />
                                    )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Quiz;