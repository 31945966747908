import fetch from '../auth/FetchInterceptor';

const QuizService = {};

QuizService.get = function (params) {
    return fetch({
        url: '/quizzes/find-for-translate',
        method: 'get',
        params,
    });
};

export default QuizService;