import React, {useState} from "react";
import Header from "./components/Header";
import Quiz from "./components/Quiz";
import {Navigate, Route, Routes, useLocation, matchPath} from "react-router-dom";
import QuizDetail from "./components/QuizDetail";
import QuizStart from "./components/QuizStart";
import QuizDone from "./components/QuizDone";
import {isNotEmptyObject} from "./utils/helpers";

const App = () => {

    const location = useLocation().pathname;

    const [search, setSearch] = useState('');

    const [locale, setLocale] = useState(isNotEmptyObject(location.split('/')[1]) ? location.split('/')[1] : 'vi');

    const isMatch = matchPath({
        path: '/:locale/quiz/:quizId/:quizName'
    }, location);

    return (
        <>
            {(location === '/vi' || location === '/en' || isNotEmptyObject(isMatch)) && <Header setSearch={setSearch} locale={locale} setLocale={setLocale}/>}

            <Routes>
                <Route path="/" element={<Navigate to={`/${locale}`} replace/>}/>
                <Route path="/:locale" element={<Quiz search={search} locale={locale}/>}/>
                <Route path="/:locale/quiz/:quizId/:quizName" element={<QuizDetail locale={locale}/>}/>
                <Route path="/:locale/:quizId/start" element={<QuizStart locale={locale} />}/>
                <Route path="/:locale/quiz/completed" element={<QuizDone locale={locale}/>} />
            </Routes>
        </>
    );
}

export default App;
