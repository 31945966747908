import axios from 'axios';
import qs from 'qs';
import {BASE_URL_API} from "../constants/constants";

const service = axios.create({
    baseURL: BASE_URL_API,
    timeout: 60000,
    paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
    },
});

// service.interceptors.request.use(
//     (config) => {
//         const jwtToken = localStorage.getItem(AUTH_TOKEN);
//
//         if (jwtToken) {
//             config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
//         }
//
//         // if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
//         //   history.push(ENTRY_ROUTE);
//         //   window.location.reload();
//         // }
//
//         return config;
//     },
//     (error) => {
//         // Do something with request error here
//         notification.error({
//             message: 'Error',
//         });
//         Promise.reject(error);
//     },
// );

export default service;