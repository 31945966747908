import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import QuizService from "../services/Quiz";
import Skeleton from "react-loading-skeleton";
import {PlayCircleOutlined} from "@ant-design/icons";
import {setBg} from "../utils/helpers";

const QuizDetail = ({locale}) => {
    const params = useParams();

    const [quiz, setQuiz] = useState({});
    const [loading, setLoading] = useState(true);

    const getQuiz = async () => {
        setLoading(true);
        const quiz = await QuizService.get({
            locale: params['locale'],
            filters: {
                id: params['quizId']
            }
        });

        setQuiz(quiz?.data?.results?.[0]);
        setLoading(false);
    }

    useEffect(() => {
        if (params.hasOwnProperty('quizId') && params.quizId) {
            getQuiz();
        }
    }, [params]);

    return (
        <>
            <div style={{backgroundColor: 'rgb(242, 242, 242)'}}>
                <div className="container pt-5">
                    <div className="quiz-detail">
                        <div className="row mb-5">
                            <div className="col-12 col-md-2">
                                <div className="quiz-detail-img" style={{backgroundColor: setBg()}}>{quiz?.name?.charAt(0)}</div>
                            </div>
                            <div className="col-12 col-md-10 d-flex flex-column justify-content-between">
                                <div>
                                    <div style={{ border: '1px solid #ccc'}} className="text-uppercase quiz-tag">Quiz</div>
                                    <p className="limit-2-line" style={{fontSize: '28px'}}>{loading ? <Skeleton count={2} /> : quiz?.name}</p>
                                    <div className="quiz-total-answer">{loading ? <Skeleton width={120} /> : quiz?.['questions'].count + (locale === 'vi' ? ' Câu hỏi' : ' Questions')}</div>
                                </div>

                                {/*{quiz?.['questions'].count}*/}

                                <Link style={{cursor: quiz?.['questions']?.count === 0 ? 'not-allowed' : 'pointer'}} to={quiz?.['questions']?.count === 0 ? '#' : `/${locale}/${quiz.id}/start`} state={{quiz: quiz}} className="quiz-start quiz-start-btn">
                                    <PlayCircleOutlined /> <span className="d-inline-block">
                                    {locale === 'vi' ? 'Bắt đầu' : 'Start'}
                                </span>
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {
                                    loading ?
                                        <Skeleton count={10}/>
                                        :
                                        <div dangerouslySetInnerHTML={{__html: quiz?.content}} className="quiz-content" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuizDetail;