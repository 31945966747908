import _ from "lodash";

export const setBg = () => {
    return '#'+(0x1000000+Math.random()*0xffffff).toString(16).substr(1,6)
}

export const convertToSlug = (string) => {
    return string.toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
}

export const isNotEmptyObject = (obj) => {
    if (typeof obj === "undefined") {
        return false;
    } else if (typeof obj === "object" && _.isEmpty(obj)) {
        return false;
    } else if (obj === null || obj === "") {
        return false;
    } else {
        return true;
    }
};