import React from "react";
import {Link, useLocation} from "react-router-dom";
import _ from 'lodash';

const QuizDone = ({locale}) => {

    const location = useLocation();

    const scores = location?.state?.scores;
    const score = location?.state?.score;
    const comment = location?.state?.comment;

    return (
        <>
            <div
                style={{
                    width: '100%',
                    minHeight: '100vh',
                    backgroundImage: 'linear-gradient(to right, rgba(38, 142, 255, 0.8), rgba(91, 117, 255, 0.7)), url(/images/bg_quiz.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center right',
                    backgroundSize: 'cover',
                    overflow: 'hidden',
                    color: '#fff'
                }}
            >
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="table-responsive">
                                <table className="table" style={{color: '#fff'}}>
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">{locale === 'vi' ? 'Số điểm' : 'Score'}</th>
                                        <th scope="col">{locale === 'vi' ? 'Kết quả' : 'Result'}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        scores && _.map(scores, (score, index) => (
                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{`${score['score_min']} - ${score['score_max']}`}</td>
                                                <td>{score['comment']}</td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="col-12 mt-3">
                            {locale === 'vi' ? 'Số điểm của bạn là' : 'Your score is'} {score}
                            <p>
                                {comment ?? ''}
                            </p>
                        </div>

                        <div className="col-12">
                            <button className="btn btn-primary">
                                <Link to={`/${locale}`} className="quiz-link">
                                    {locale === 'vi' ? 'Làm bài quiz khác' : 'Take another quiz'}
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuizDone;