import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {isNotEmptyObject} from "../utils/helpers";
import {ArrowLeftOutlined, ArrowRightOutlined, CheckOutlined} from "@ant-design/icons";
import _ from "lodash";
import 'animate.css';

const convertLabel = (index) => {
    return String.fromCharCode(index + 'A'.charCodeAt(0));
}

const convertColor = (alphabet) => {
    switch (alphabet) {
        case 'A':
            return 'answer-color pinkLady';
        case 'B':
            return 'answer-color salomie';
        case 'C':
            return 'answer-color wePeep';
        case 'D':
            return 'answer-color periwinkle';
        default:
            return 'answer-color pinkLady';
    }
}

const QuizStart = ({locale}) => {

    const navigate = useNavigate();

    const [choice, setChoice] = useState();

    const location = useLocation();

    const {quiz} = location.state;

    const [questions, setQuestions] = useState(quiz?.['questions']?.['results']);

    const [currentQuestion, setCurrentQuestion] = useState(0);

    const [answerSelected, setAnswerSelected] = useState([]);

    const [clickNext, setClickNext] = useState(false);
    const [clickPrev, setClickPrev] = useState(false);
    const [submit, setSubmit] = useState(false);

    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(30);

    const handlePrevQuestion = () => {
        setCurrentQuestion(currentQuestion - 1);
        setClickNext(false);
        setClickPrev(true);
        setSubmit(false);
    }

    const handleNextQuestion = () => {
        setCurrentQuestion(currentQuestion + 1);
        setClickNext(true);
        setClickPrev(false);
        setSubmit(false);
    }

    const handleChange = (e) => {
        setClickNext(false);
        setClickPrev(false);
        const value = Number(e.target.value);
        setChoice(value);

        const answer = {
            questionId: questions[currentQuestion]?.['id'],
            answer: questions[currentQuestion]?.['answers']?.['results'].find(item => item.id === value)
        };

        setAnswerSelected(prev => [...prev, answer]);
    };

    const handleSubmitQuiz = () => {
        setSubmit(true);

        setTimeout(() => {
            const score = localStorage.getItem('score');
            // console.log('score', score)

            let resultText = '';
            const scoreInterpretation = quiz?.['scoreInterpretation'];
            const calcScore = scoreInterpretation.find(item => (score >= item['score_min'] && score <= item['score_max']))?.['comment'];
            if (isNotEmptyObject(calcScore)) {
                resultText += scoreInterpretation.find(item => (score >= item['score_min'] && score <= item['score_max']))?.['comment'];
            }

            navigate(`/${locale}/quiz/completed`, {
                state: {
                    score: score,
                    comment: resultText,
                    scores: quiz?.['scoreInterpretation']
                }
            });
        }, 500);
    }

    useEffect(() => {
        let countdown = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(countdown);
                } else {
                    setMinutes(minutes - 1)
                    setSeconds(59);
                }
            }

            if (minutes === 0 && seconds === 0) {
                handleSubmitQuiz();
            }

        }, 1000)
        return () => {
            clearInterval(countdown);
        }
    })

    let strMinutes;
    let strSeconds;

    strMinutes = minutes < 10 ? `0${minutes}` : minutes
    strSeconds = seconds < 10 ? `0${seconds}` : seconds

    const time = `${strMinutes}:${strSeconds}`

    useEffect(() => {
        const element = document.getElementsByClassName('answer-item');
        const questionTitle = document.getElementById('question_title');

        questionTitle.classList.add('animate__animated', 'animate__fadeInDown', 'animate__delay-0.5s');

        let duration = 0.5;

        _.forEach(element, item => {
            duration += 0.2;
            item.classList.add('animate__animated', 'animate__fadeInLeft');
            item.style.setProperty('--animate-duration', `${duration}s`);
        });
    }, [currentQuestion]);

    useEffect(() => {
        let newQuestions = [...questions];
        const answer = answerSelected[answerSelected.length - 1];

        newQuestions = isNotEmptyObject(newQuestions) && newQuestions.map(question => {
            if (isNotEmptyObject(answer) && isNotEmptyObject(answer.questionId)) {
                if (question.id === answer.questionId) {
                    question?.['answers']?.['results'].map(item => item.selected = (item.id === answer.answer.id));
                }
            }

            return question;
        });

        if (_.isObject(newQuestions)) {
            setQuestions(newQuestions);

            let score = 0;
            newQuestions.map(question => {
                question?.['answers']?.['results'].map(answer => {
                    if (answer.selected) {
                        score += answer['score'];
                    }
                })
            });

            localStorage.setItem('score', score);
        }

    }, [currentQuestion, submit]);

    return (
        <>
            <div className="quiz-start-wrapper"
                 style={{
                     width: '100%',
                     minHeight: '100vh',
                     backgroundImage: 'linear-gradient(to right, rgba(38, 142, 255, 0.8), rgba(91, 117, 255, 0.7)), url(/images/bg_quiz.png)',
                     backgroundRepeat: 'no-repeat',
                     backgroundPosition: 'center right',
                     backgroundSize: 'cover',
                     overflow: 'hidden'
                 }}
            >

                <img className="img-other" src="/images/bg_1.png" alt=""/>

                <div className="container-fluid">
                    <div className="time-out">
                        <div className="form_timer rounded-pill countdown_timer">
                            <div className="time">
                                <div
                                    className={minutes === 0 && seconds <= 10 ? "icon-reminder clock" : "icon-reminder"}>
                                    <img src="/images/alarm.png" alt=""/>
                                </div>
                            </div>
                            <span
                                className={minutes === 0 && seconds <= 10 ? "animation-change-color text-white" : "text-white"}>
                            {
                                time
                            }
                        </span>
                        </div>
                    </div>
                </div>

                <div className="container answer">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="question">
                                <div className="question_title pb-4 position-relative" id="question_title">
                                    <h1 className="mt-2 text-align-center"
                                        // dangerouslySetInnerHTML={{__html: questions[currentQuestion]?.content}}
                                    >
                                        {questions[currentQuestion]?.content}
                                    </h1>

                                    {/*<div className="triangle triangle-5" />*/}

                                    {/*{*/}
                                    {/*    (isNotEmptyObject(questions) && isNotEmptyObject(questions[currentQuestion]?.['answers']?.['results'])) ?*/}
                                    {/*        (*/}
                                    {/*            <ol className="timeline">*/}
                                    {/*                {*/}
                                    {/*                    questions[currentQuestion]?.['answers']?.['results'].map((answer, index) => (*/}
                                    {/*                        <li className="timeline-item" key={index}>*/}
                                    {/*                            <span className="timeline-item-icon | faded-icon">*/}

                                    {/*                            </span>*/}
                                    {/*                        </li>*/}
                                    {/*                    ))*/}
                                    {/*                }*/}
                                    {/*            </ol>*/}
                                    {/*        )*/}
                                    {/*        : null*/}
                                    {/*}*/}
                                </div>
                            </div>

                            {
                                (isNotEmptyObject(questions) && isNotEmptyObject(questions[currentQuestion]?.['answers']?.['results']))
                                && questions[currentQuestion]?.['answers']?.['results'].map((answer, index) => (
                                    <div key={answer.id} style={{marginLeft: '18%'}} className="position-relative">
                                        <label htmlFor={answer.id} className="answer-label zmp-radio-label">
                                            <div
                                                className={(choice == answer.id || answer.selected) ? "answer-item active" : "answer-item"}>
                                                <p className="ml-3 mb-0">{answer.content}</p>
                                                <span className="answer-order">{convertLabel(index)}</span>
                                                <span className={convertColor(convertLabel(index))}/>
                                            </div>
                                        </label>

                                        <input id={answer.id} type="radio" className="question-radio" name="questions"
                                               value={answer.id} onChange={e => handleChange(e)}/>

                                        {
                                            (choice == answer.id || answer.selected) &&
                                            <CheckOutlined/>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                        <div className="col-12 col-md-6">

                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-6 btn-redirect">
                            <div className="text-align-center mt-6 mb-10">
                                {
                                    currentQuestion > 0 && (
                                        <div className="button-redirect button-redirect-left mr-4"
                                             onClick={handlePrevQuestion}>
                                            <ArrowLeftOutlined/>
                                            <span>
                                                {locale === 'en' ? 'Last Question' : 'Quay lại'}
                                            </span>
                                        </div>
                                    )
                                }

                                <div className="button-redirect button-redirect-right">
                                    {
                                        currentQuestion === questions.length - 1
                                            ?
                                            <span onClick={handleSubmitQuiz}>
                                                {locale === 'en' ? 'Submit' : 'Hoàn thành'}
                                            </span>
                                            :
                                            (
                                                <div onClick={handleNextQuestion}>
                                                    <span>
                                                        {locale === 'en' ? 'Next Question' : 'Tiếp theo'}
                                                    </span>
                                                    <ArrowRightOutlined/>
                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuizStart;