import React, {useState} from "react";
import {RightOutlined, SearchOutlined} from "@ant-design/icons";
import {Link, useNavigate} from "react-router-dom";
import Select from 'react-select';

const options = [
    { value: 'en', label: 'EN', image: '/images/united-kingdom.png' },
    { value: 'vi', label: 'VI', image: '/images/vietnam.png' },
];

const Header = ({setSearch, locale, setLocale}) => {

    const [value, setValue] = useState('');

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        setSearch(value);
    }

    return (
        <>
            <nav className="navbar navbar-light bg-light">
                <div className="container">
                    <Link to="/" className="navbar-brand">
                        <img src="/images/logo.png" alt=""/>
                    </Link>
                    <form onSubmit={handleSubmit} className="d-flex search-form position-relative" style={{width: '60%'}}>
                        <SearchOutlined className="icon-search-placeholder" />
                        <input onChange={e => setValue(e.target.value)} type="text" className="form-control px-5" placeholder="Tìm kiếm" />
                        <span className="divide">|</span>
                        <button type="submit">
                            <RightOutlined className="icon-search" />
                        </button>
                    </form>

                    <Select
                        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                        options={options}
                        isSearchable={false}
                        defaultValue={locale === 'vi' ? { value: 'vi', label: 'VI', image: '/images/vietnam.png' } : { value: 'en', label: 'EN', image: '/images/united-kingdom.png' }}
                        formatOptionLabel={item => (
                            <div className="country-option">
                                <img width="17px" src={item.image} alt="country-image" />
                                <span style={{paddingLeft: '4px', position: "relative", top: '1px'}}>{item.label}</span>
                            </div>
                        )}
                        onChange={value => {
                            setLocale(value.value);
                            navigate(`/${value.value}`)
                        }}
                    />
                </div>
            </nav>
        </>
    );
}

export default Header;
